import { useSearch } from '@unified-commerce/gpc-vue-storefront-search-io';

const useSearchIOBundledProducts = () => {
  const { search, result, error, loading } = useSearch(`bundledProductsSearchIO`);

  const getSearchIOBundledProducts = async (skus: string[]) => {
    const escapedASKUs = skus.map((sku) => `"${sku}"`).join(',');
    if (!escapedASKUs.length) return;

    await search({
      filter: `metafield_plytix__sku IN (${escapedASKUs})`,
    });
    if (error.value.search) throw error.value.search;
    if (!result?.value) return;

    return result?.value.results;
  };

  return {
    loading,
    getSearchIOBundledProducts,
  };
};

export default useSearchIOBundledProducts;
