import { computed, onUnmounted, Ref, ref, useRoute } from '@nuxtjs/composition-api';
import { Product, useProduct } from '@unified-commerce/gpc-vue-storefront-shopify';
import { BundledProductInformation } from '@unified-commerce/gpc-vue-storefront-shopify/apollo/src/types';
import { sharedRef } from '@vue-storefront/core';

import { IBundledProduct } from '~/components/@types/IBundledProduct';
import { useShopifyCartVariants } from '~/composables';
import { mergeSearchIOAndShopifyProducts } from '~/composables/useBundledProducts/mergeSearchIOAndShopifyProducts';
import { idToProductGid } from '~/composables/useBundledProducts/shopifyGidUtils';
import { sortBySkuOrder } from '~/composables/useBundledProducts/sortBySkuOrder';
import useSearchIOBundledProducts from '~/composables/useBundledProducts/useSearchIOBundledProducts';

const hasBundledProductValidationError = ref(false);

const useBundledProducts = () => {
  const route = useRoute();
  const { slug } = route.value.params;
  const bundledProducts: Ref<IBundledProduct[]> = sharedRef([], `bpo-${slug}`);
  const { shopifyCartVariants } = useShopifyCartVariants();
  const { getSearchIOBundledProducts, loading: searchIOLoading } = useSearchIOBundledProducts();

  const resetState = () => {
    hasBundledProductValidationError.value = false;
  };

  onUnmounted(() => {
    resetState();
  });

  const { search, products, error, loading: shopifyLoading } = useProduct(`bundledProducts`);

  const getBundledProducts = async (bundles: Partial<BundledProductInformation>[]) => {
    const skus = bundles.map(({ sku }) => sku).filter(Boolean) as string[];
    const searchIOResult = await getSearchIOBundledProducts(skus);
    if (!searchIOResult) return;

    const ids = searchIOResult
      .sort(sortBySkuOrder(skus))
      .map(({ record }) => idToProductGid(record.id));
    await search({ ids });
    if (error?.value?.search) throw error.value.search;

    bundledProducts.value = mergeSearchIOAndShopifyProducts(
      bundles,
      searchIOResult,
      products.value as unknown as Product[],
    );
  };

  const isValid = computed(
    () =>
      !!bundledProducts.value?.length &&
      shopifyCartVariants.value.length === bundledProducts.value.length,
  );

  const validate = () => {
    hasBundledProductValidationError.value = !isValid.value;
    return isValid.value;
  };

  const loading = computed(() => searchIOLoading.value || shopifyLoading.value);

  return {
    loading,
    isValid,
    validate,
    resetState,
    bundledProducts,
    getBundledProducts,
    hasBundledProductValidationError,
  };
};

export default useBundledProducts;
