import { IProductResult } from '@unified-commerce/gpc-vue-storefront-search-io';
import { Product } from '@unified-commerce/gpc-vue-storefront-shopify';
import { BundledProductInformation } from '@unified-commerce/gpc-vue-storefront-shopify/apollo/src/types';

import { IBundledProduct } from '~/components/@types/IBundledProduct';
import { getSearchIOBundleVariantSummary } from '~/composables/useBundledProducts/getSearchIOBundleVariantSummary';
import { getShopifyBundleVariantSummary } from '~/composables/useBundledProducts/getShopifyBundleVariantSummary';
import { gidToId } from '~/composables/useBundledProducts/shopifyGidUtils';

export const mergeSearchIOAndShopifyProducts = (
  bundles: Partial<BundledProductInformation>[],
  searchIOResults: IProductResult[],
  shopifyProducts: Product[],
): IBundledProduct[] =>
  shopifyProducts.map((product: Product) => {
    const { id, name, image, options, collections, vendor } =
      getShopifyBundleVariantSummary(product);
    const { parentSKU, variants } = getSearchIOBundleVariantSummary(
      searchIOResults,
      gidToId(product.id),
    );
    const quantityInBundle = bundles.find(({ sku }) => sku === parentSKU)?.quantity ?? 1;

    return {
      id,
      name,
      image,
      options,
      collections,
      vendor,
      quantityInBundle,
      parentSKU,
      variants,
    };
  });
