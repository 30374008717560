import { onUnmounted, ref } from '@nuxtjs/composition-api';

import { ShopifyCartVariant } from '~/components/@types/IBundledProduct';

const shopifyCartVariants = ref<ShopifyCartVariant[]>([]);

const useShopifyCartVariants = () => {
  const setShopifyCartVariant = (val: ShopifyCartVariant[]) => {
    shopifyCartVariants.value = val;
  };

  const resetState = () => {
    shopifyCartVariants.value = [];
  };

  onUnmounted(() => {
    resetState();
  });

  return {
    shopifyCartVariants,
    setShopifyCartVariant,
  };
};

export default useShopifyCartVariants;
