import { IProductResult } from '@unified-commerce/gpc-vue-storefront-search-io';

import {
  ISearchIOVariantSummary,
  SearchIOBundleVariantSummary,
} from '~/components/@types/IBundledProduct';
import { idToVariantGid } from '~/composables/useBundledProducts/shopifyGidUtils';

const variantSummariesFromSearchIOProductResult = (
  record: IProductResult['record'],
): ISearchIOVariantSummary[] =>
  record.variant_options_1.map((variantOptionOne, index) => ({
    values: [variantOptionOne, record.variant_options_2[index], record.variant_options_3[index]]
      .filter(Boolean)
      .map((value) => ({ value })),
    variantId: idToVariantGid(record.variant_ids[index]),
    inStock:
      !!record.variant_options_1_in_stock[index] ||
      !!record.variant_options_2_in_stock[index] ||
      !!record.variant_options_3_in_stock[index],
  }));

interface VariantDetailsIndex {
  [key: string]: SearchIOBundleVariantSummary;
}

export const getSearchIOBundleVariantSummary = (
  searchIOProductResults: IProductResult[],
  id: string,
) => {
  const variantDetailsIndex = searchIOProductResults.reduce<VariantDetailsIndex>(
    (accum, { record }) => {
      if (!record) return accum;
      accum[record.id] = accum[record.id] ?? {
        parentSKU: record.metafield_plytix__sku,
        variants: variantSummariesFromSearchIOProductResult(record),
      };
      return accum;
    },
    {},
  );

  return variantDetailsIndex[id];
};
