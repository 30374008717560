/* eslint-disable max-lines-per-function */
/* istanbul ignore file */
import { getCurrentInstance, Ref, unref } from '@nuxtjs/composition-api';
import { Filter } from '@unified-commerce/gpc-vue-storefront-shopify';
import { Logger } from '@vue-storefront/core';
import qs from 'qs';
import { Route } from 'vue-router';

import { getPriceRangeQueryValue } from '~/components/SearchFacetFilters/helpers';
import {
  CATEGORY_QUERY_PARAM,
  CATEGORY_ROUTE_PREFIX,
  MAX_PRICE_RANGE_QUERY_PREFIX,
  MIN_PRICE_RANGE_QUERY_PREFIX,
  PRICE_RANGE_QUERY_PARAM,
  SEARCH_CATEGORY_HANDLE_SEPARATOR,
  SEARCH_CATEGORY_PATH_SEPERATOR,
  SEARCH_CATEGORY_PROMOTIONS_PREFIX_HANDLE,
  SEARCH_CATEGORY_QUERY_SEPARATOR,
  SEARCH_PRICE_FILTER,
  SEARCH_PRICE_RANGES,
  STORYBLOK_CATEGORY_ROUTE_PREFIX,
} from '~/constants';
import { isPromotionsRoute } from '~/helpers/promotions/isPromotionsRoute';
import { sanitizeLinkUrl } from '~/helpers/sanitizeLinkUrl';
import {
  ISearchCategoryFilter,
  ISearchPriceRange,
  ISearchPriceRangeFilter,
} from '~/types/searchio';
import { ICTAStoryblokButtonLink } from '~/types/storyblok';

import appendAutoplayToYouTubeEmbedURL from './appendAutoplayToYouTubeEmbedURL/index';
import getBrandLinkFromSlug from './getBrandLinkFromSlug';
import getByString from './getByString';
import getFilterLink from './getFilterLink/index';
import getPlaceholderImage from './getPlaceholderImage';
import getPromosLinkFromSlug from './getPromosLinkFromSlug/index';
import getPromotionsCategoryLink from './getPromotionsCategoryLink';
import getSearchQueryLink from './getSearchQueryLink/index';
import getYouTubeEmbedURL from './getYoutubeEmbedURL/index';
import isFacetValueInQuery from './isFacetValueInQuery/index';
import { lockPageScrollAllViewports, lockPageScrollOnMobileOnly } from './lockPageScroll';
import { unlockPageScrollAllViewports, unlockPageScrollOnMobileOnly } from './unlockPageScroll';
const nonFilters = ['page', 'sort', 'term', 'itemsPerPage'];

interface IRouteError {
  errorMessage?: string;
  data?: unknown;
}

const getContext = () => {
  const vm = getCurrentInstance();

  if (vm) {
    return vm.root.proxy;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reduceFilters = (query: any) => (prev: any, curr: string) => {
  const makeArray = Array.isArray(query[curr]) || nonFilters.includes(curr);

  return {
    ...prev,
    [curr]: makeArray ? query[curr] : [query[curr]],
  };
};

const getFiltersDataFromUrl = (context: Vue, onlyFilters: boolean) => {
  const { query } = context.$router.currentRoute;

  return Object.keys(query)
    .filter((f) => (onlyFilters ? !nonFilters.includes(f) : nonFilters.includes(f)))
    .reduce(reduceFilters(query), {});
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUiHelpers = () => {
  const context = getContext();

  const getCurrentRoute = () => {
    if (!context) {
      return;
    }
    return Object.assign({}, context.$router.currentRoute);
  };

  const getQueryURL = (paramsToRemove: string[], queriesToAdd: Record<string, string> = {}) => {
    if (!context) {
      return;
    }
    const currentQuery = { ...context.$router.currentRoute.query };
    const currentPath = context.$router.currentRoute.path;
    paramsToRemove.forEach((param) => {
      delete currentQuery[param];
    });

    const queryURL =
      currentPath + qs.stringify({ ...currentQuery, ...queriesToAdd }, { addQueryPrefix: true });
    return queryURL;
  };
  const setCurrentRouteWithError = (
    statusCode: number,
    errorMessage: string,
    data?: unknown,
  ): void => {
    if (!context) {
      return;
    }

    const { fullPath, ...route } = context.$route;

    const errorContext: Record<string, unknown> = {
      statusCode,
      errorMessage,
      route,
    };

    if (data) {
      errorContext.data = data;
    }

    context.$nuxt.error({
      statusCode,
      message: errorMessage,
    });
  };

  const setCurrentRouteAsNotFound = (errorParams?: IRouteError): void => {
    const errorMessage = errorParams?.errorMessage || 'This page could not be found';
    Logger.error(errorMessage, errorParams?.data);
    setCurrentRouteWithError(404, errorMessage, errorParams?.data);
  };

  const setCurrentRouteAsInternalError = (errorParams?: IRouteError): void => {
    const errorMessage = errorParams?.errorMessage || 'Something went wrong';

    setCurrentRouteWithError(500, errorMessage, errorParams?.data);
  };

  const getQueryParam = (queryParamName: string): string | null => {
    if (!context) {
      return null;
    }

    const { query } = context.$route;

    if (query[queryParamName]) {
      return decodeURIComponent(query[queryParamName].toString());
    }

    return '';
  };

  const getCurrentRouteParams = (): Record<string, string> | undefined | null => {
    if (!context) {
      return;
    }

    const { params } = context.$router.currentRoute;

    if (Object.entries(params).length === 0) {
      return null;
    }

    return params;
  };

  const getCurrentQuery = (): Record<string, string | (string | null)[]> | undefined | null => {
    if (!context) {
      return;
    }

    const { query } = context.$router.currentRoute;

    if (Object.entries(query).length === 0) {
      return null;
    }

    return query;
  };
  const getPathParam = (pathParamName: string): string => {
    if (!context) {
      return '';
    }

    const { params } = context.$route;

    return params[pathParamName];
  };

  const addQueryParam = (
    queryParamName: string,
    queryParamValue: string,
    replaceAllCurrentParams = false,
  ) => {
    if (!context) {
      return '';
    }

    const { query } = context.$route;

    return qs.stringify(
      replaceAllCurrentParams
        ? {
            [queryParamName]: queryParamValue,
          }
        : {
            ...query,
            [queryParamName]: queryParamValue,
          },
      {
        addQueryPrefix: true,
      },
    );
  };

  const setQueryParamInCurrentPath = async (
    queryParamName: string,
    queryParamValue: string,
  ): Promise<void> => {
    if (!context) {
      return;
    }

    await context.$router.push({
      path: context.$route.path,
      query: {
        ...context.$route.query,
        [queryParamName]: queryParamValue,
      },
    });
  };

  const removeQueryParam = (queryParamName: string) => {
    if (!context) {
      return '';
    }

    const { query, path } = context.$route;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [queryParamName]: _, ...restOfQueryParams } = query;

    const queryString = qs.stringify(restOfQueryParams, {
      addQueryPrefix: true,
    });

    if (!queryString) {
      return path;
    }

    return queryString;
  };

  const getFacetsFromURL = () => {
    if (!context) return;
    const { query, params } = context.$router.currentRoute;
    const categorySlug = Object.keys(params).reduce(
      (prev, curr) => params[curr] || prev,
      params.slug_1,
    );

    return {
      rootCatSlug: params.slug_1,
      categorySlug,
      page: parseInt(query.page as string, 10) || 1,
      sort: query.sort || 'latest',
      filters: getFiltersDataFromUrl(context, true),
      itemsPerPage: parseInt(query.itemsPerPage as string, 12) || 20,
      term: query.term,
    };
  };

  /**
   * **NOTE**: This function can only be used on the client-side, where the `window` object is defined.
   *
   * It can be used in `methods` or in client-side lifecycle hooks such as `mounted`.
   */
  const getCurrentAbsoluteURL = (): string => {
    if (!context) {
      return '';
    }

    const origin = window.location.origin;
    const routePath = context.$route.path;

    return `${origin}${routePath}`;
  };

  const changeSorting = (sort: string) => {
    if (!context) return;
    const { query } = context.$router.currentRoute;
    context.$router.push({ query: { ...query, sort } });
  };

  const changeFilters = (filters: Filter[]) => {
    if (!context) return;
    context.$router.push({
      query: {
        ...getFiltersDataFromUrl(context, false),
        ...filters,
      },
    });
  };

  const changeItemsPerPage = (itemsPerPage: number) => {
    if (!context) return;
    context.$router.push({
      query: {
        ...getFiltersDataFromUrl(context, false),
        itemsPerPage,
      },
    });
  };

  const changeSearchTerm = (term: string) => {
    if (!context) return;
    context.$router.push({
      query: {
        ...getFiltersDataFromUrl(context, false),
        term: term || undefined,
      },
    });
  };

  const isCategoriesRoute = (routeArg: Ref<Route> | Route) => {
    const route = unref(routeArg);
    return (
      route.path.startsWith(`/${CATEGORY_ROUTE_PREFIX}`) ||
      route.path.startsWith(`/${STORYBLOK_CATEGORY_ROUTE_PREFIX}`)
    );
  };
  const getCategoryFromVueRouteQuery = (routeArg: Ref<Route> | Route) => {
    const route = unref(routeArg);
    const categoryQueryParam = route.query[CATEGORY_QUERY_PARAM] ?? '';
    return decodeURIComponent(categoryQueryParam.toString())
      .split(SEARCH_CATEGORY_QUERY_SEPARATOR)
      .join(SEARCH_CATEGORY_HANDLE_SEPARATOR);
  };

  const getCategoryFromVueRouteParams = (routeArg: Ref<Route> | Route) => {
    const route = unref(routeArg);
    const categoryParams = Object.values(route.params).filter((param) => !!param);
    return categoryParams.join(SEARCH_CATEGORY_HANDLE_SEPARATOR);
  };

  const getActiveCategory = (route: Ref<Route> | Route): string => {
    if (isPromotionsRoute(route)) {
      return `${SEARCH_CATEGORY_PROMOTIONS_PREFIX_HANDLE}${getCategoryFromVueRouteParams(route)}`;
    }
    if (isCategoriesRoute(route)) {
      return getCategoryFromVueRouteParams(route);
    }
    return getCategoryFromVueRouteQuery(route);
  };

  const getActiveCategoryTitle = (
    categoryFilters: ISearchCategoryFilter[],
    categoryHandle: string,
  ): string =>
    categoryFilters.find((categoryFilter) => categoryFilter.exactHandle === categoryHandle)
      ?.exactTitle || '';

  const getPromotionCategoryExactTitle = (categoryTitle: string): string => {
    if (categoryTitle.includes('Promotion')) {
      return categoryTitle.split(SEARCH_CATEGORY_PATH_SEPERATOR).pop() || '';
    }

    return '';
  };

  const getBrandCategoryExactTitle = (categoryTitle: string): string => {
    if (categoryTitle.includes('Brand')) {
      return categoryTitle.split(SEARCH_CATEGORY_PATH_SEPERATOR).pop() || '';
    }

    return '';
  };

  const getAppliedPriceRanges = (priceRangesQueryParam?: string) => {
    if (!context && !priceRangesQueryParam) {
      return [];
    }

    const parsePrice = (priceString: string): number => parseInt(priceString.replace('$', ''), 10);

    const appliedPriceRangesQueryParam =
      priceRangesQueryParam || getQueryParam(PRICE_RANGE_QUERY_PARAM);

    if (appliedPriceRangesQueryParam) {
      const priceRanges = appliedPriceRangesQueryParam.split(',');

      return priceRanges.map((priceRange) => {
        if (priceRange.startsWith(MAX_PRICE_RANGE_QUERY_PREFIX)) {
          const maxPrice = parsePrice(priceRange.replace(MAX_PRICE_RANGE_QUERY_PREFIX, ''));

          return {
            maxPrice,
          };
        } else if (priceRange.startsWith(MIN_PRICE_RANGE_QUERY_PREFIX)) {
          const minPrice = parsePrice(priceRange.replace(MIN_PRICE_RANGE_QUERY_PREFIX, ''));

          return {
            minPrice,
          };
        }

        const [minPrice, maxPrice] = priceRange.split('-');

        return {
          minPrice: parsePrice(minPrice),
          maxPrice: parsePrice(maxPrice),
        };
      });
    }

    return [];
  };

  const getSearchPriceRangeFilter = (): ISearchPriceRangeFilter => {
    const appliedPriceRanges = getAppliedPriceRanges();
    const priceRanges = SEARCH_PRICE_RANGES;

    const findCustomPriceRange = (
      priceRanges: ISearchPriceRange[],
      appliedPriceRanges: ISearchPriceRange[],
    ) => {
      for (const AppRange of appliedPriceRanges) {
        if (
          !priceRanges.some(
            (range) => range.minPrice === AppRange.minPrice && range.maxPrice === AppRange.maxPrice,
          )
        ) {
          return AppRange;
        }
      }
      return;
    };

    const customRange = findCustomPriceRange(priceRanges, appliedPriceRanges);

    const minPrice = customRange?.minPrice ?? null;
    const maxPrice = customRange?.maxPrice ?? null;

    return {
      priceRanges,
      minPrice,
      maxPrice,
      appliedPriceRanges,
    };
  };

  const setPriceRangeQuery = async (priceRange: ISearchPriceRange) => {
    if (!context) {
      return;
    }

    context.$router.push({
      query: {
        ...context.$route.query,
        [PRICE_RANGE_QUERY_PARAM]: getPriceRangeQueryValue(priceRange),
      },
    });
  };

  const getSearchPriceRangeFilterValue = (priceRanges: ISearchPriceRange[]): string => {
    return priceRanges
      .map((priceRange) => {
        if (priceRange.maxPrice && priceRange.minPrice) {
          return `(${SEARCH_PRICE_FILTER} >= ${priceRange.minPrice} AND ${SEARCH_PRICE_FILTER} <= ${priceRange.maxPrice})`;
        } else if (!priceRange.maxPrice && priceRange.minPrice) {
          return `(${SEARCH_PRICE_FILTER} >= ${priceRange.minPrice})`;
        } else if (!priceRange.minPrice && priceRange.maxPrice) {
          return `(${SEARCH_PRICE_FILTER} <= ${priceRange.maxPrice})`;
        }

        return '';
      })
      .join(' OR ');
  };

  const getCookieName = (appIdentifier: string, cookieName: string): string => {
    return `${appIdentifier}_${cookieName}`;
  };

  const getCTALink = (buttonLink: ICTAStoryblokButtonLink) => {
    if (buttonLink?.url.includes('categories') || buttonLink?.cached_url.includes('categories')) {
      return (
        buttonLink?.url.replace('categories/', 'c/') ||
        buttonLink?.cached_url.replace('categories/', 'c/')
      );
    }
    return sanitizeLinkUrl(buttonLink?.url || buttonLink?.cached_url);
  };

  return {
    getCategoryFromVueRouteQuery,
    getActiveCategory,
    getSearchPriceRangeFilter,
    getAppliedPriceRanges,
    setPriceRangeQuery,
    getSearchPriceRangeFilterValue,
    getActiveCategoryTitle,
    getPromotionCategoryExactTitle,
    getBrandCategoryExactTitle,
    setCurrentRouteAsNotFound,
    setCurrentRouteAsInternalError,
    getPathParam,
    getQueryParam,
    getCurrentQuery,
    addQueryParam,
    removeQueryParam,
    getQueryURL,
    getFacetsFromURL,
    changeSorting,
    changeFilters,
    changeItemsPerPage,
    changeSearchTerm,
    getYouTubeEmbedURL,
    appendAutoplayToYouTubeEmbedURL,
    getFilterLink,
    isFacetValueInQuery,
    getBrandLinkFromSlug,
    getCurrentAbsoluteURL,
    getPlaceholderImage,
    getByString,
    getPromosLinkFromSlug,
    getPromotionsCategoryLink,
    getCurrentRouteParams,
    getCurrentRoute,
    setQueryParamInCurrentPath,
    getSearchQueryLink,
    getCookieName,
    lockPageScrollOnMobileOnly,
    lockPageScrollAllViewports,
    unlockPageScrollOnMobileOnly,
    unlockPageScrollAllViewports,
    getCTALink,
  };
};

export default useUiHelpers;
