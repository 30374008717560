/* eslint-disable @typescript-eslint/no-explicit-any */
import { cartGetters } from '@unified-commerce/gpc-vue-storefront-shopify';

import { useUiHelpers } from '~/composables';
import { IS_LOGIN_FROM_CHECKOUT_COOKIE } from '~/constants';

export const LoginErrorMessages = {
  VALIDATION_ERROR:
    'Those details don’t look correct, check your details and give it another crack',
  INTERNAL_ERROR: "Sorry we couldn't process your request at this time. Please try again later",
};
export const loginUser = async (
  isLoginLoading: { value: boolean },
  route: { value: any },
  loginFn: any,
  form: {
    value: { [key: string]: string };
  },
  cookies: any,
  user: { value: any },
  userError: { value: any },
  error: { [key: string]: string },
  cart: { value: any },
  appIdentifier: string,
) => {
  isLoginLoading.value = true;
  const { getCookieName } = useUiHelpers();

  const isLoginFromCheckout = cookies.get(
    getCookieName(appIdentifier, IS_LOGIN_FROM_CHECKOUT_COOKIE),
  );
  const checkoutURL = cartGetters.getcheckoutURL(cart.value);
  let redirectURL = route.value.path;

  if (isLoginFromCheckout) {
    redirectURL = checkoutURL;
  } else if (route.value.path.includes('/signup') || route.value.path.includes('/login')) {
    redirectURL = '/';
  }

  await loginFn({
    user: form.value,
    customQuery: {
      redirectURL,
      isRedirectExternal: !!isLoginFromCheckout,
    },
  });

  const hasUserErrors = userError.value.login?.message;

  /**
   * Login API returns error in two ways
   * 1. For wrong credentials, error is returned as part of property `user`
   * 2. If something goes wrong i.e. for system/application error, it returns error as part of userError
   */

  if (user.value && !user.value.token && user.value.error === 'Unidentified customer') {
    error.login = LoginErrorMessages.VALIDATION_ERROR;
    isLoginLoading.value = false;
    return false;
  } else if (hasUserErrors) {
    error.login = LoginErrorMessages.INTERNAL_ERROR;
    isLoginLoading.value = false;
    return false;
  }

  cookies.remove(getCookieName(appIdentifier, IS_LOGIN_FROM_CHECKOUT_COOKIE));
  return true;
};
