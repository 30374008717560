import { Product, productGetters } from '@unified-commerce/gpc-vue-storefront-shopify';

import { IVariantOption, ShopifyBundleVariantSummary } from '~/components/@types/IBundledProduct';

export const getShopifyBundleVariantSummary = (product: Product): ShopifyBundleVariantSummary => ({
  id: productGetters.getId(product),
  name: productGetters.getName(product),
  image: productGetters.getCoverImage(product, 'medium'),
  options: productGetters.getOptions(product, true) as unknown as IVariantOption[],
  collections: productGetters.getCollections(product),
  vendor: productGetters.getVendorName(product),
});
